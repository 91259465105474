import React, { useEffect, useState } from "react";
import PreBuildData from "../../data.json";
import { useHistory } from "react-router-dom";
import courseSvc from "services/Course";
import Loader from "assets/animated-images/Loader";
import { CourseDetailPage as STRLCourseDetailPage } from "skillstrainer-resource-library";
import { courseFormatter } from "skillstrainer-resource-library/utils/course";
import { useSelector, useDispatch } from "react-redux";
import liveClassService from "services/liveClass";
import razorpayClassAPi from "services/RazorpayApi";
import razorpaySubscriptionClass from "services/RazorpaySubscriptionApi";
import Toast from "wrappers/Toast";
import { SET_COURSE_TO_DEMO } from "redux/actions/Course";
import { ACTION_TYPES } from "route-actions";
import { toastApiError } from "utils/error";
import Modal from "components/Modal";
import CheckoutModal from "./checkoutModal";

export default function CourseDetail(props) {
  const { courseId } = props.match.params;
  const [courseData, setCourseData] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [paymentEvent, setPaymentEvent] = useState({
    type: "",
    intialized: false,
  });
  const courseMultiLangData =
    PreBuildData.multiLang[`page.course.${courseId}.en`];

  const [courseModule, setCourseModule] = useState([]);

  const activeCourses = useSelector(({ course }) => course.active_courses);
  const isPurchased = activeCourses[courseId];

  const { auth } = useSelector(({ auth }) => ({ auth }));
  const user = auth.user.db_user;
  const userId = auth.user?.db_user?.id;
  const user_demo_batches = useSelector(
    ({ course }) => course.user_demo_batches
  );

  const dispatch = useDispatch();

  useEffect(async () => {
    window.scrollTop = 0;

    if (courseId) {
      // Course data
      courseSvc
        .getCourseById(courseId)
        .then((data) => {
          // //Define course img if not mdl course
          // data.image_url = data.is_moodle_course
          //   ? data.image_url
          //   : s3BaseUrl + "/" + data.image_url;

          //adding 10k users in ui for english star
          if (data.id === 31) {
            data.students_enrolled = String(
              Number(data.students_enrolled) + 10000
            );
          }

          setCourseData(data);
        })
        .catch((err) => {
          console.error(err);
          setCourseData(false);
        });

      // Course modules
      const res = await liveClassService.getCourseSection(courseId);
      setCourseModule(res);
    } else setCourseData(false);
  }, []);

  const history = useHistory();

  /*******Plain Razorpay payment *****/
  const PayByRazorpay = async (
    gst_no = null,
    business_name = null,
    billing_address_id = null
  ) => {
    console.log("paying by rajorpay");

    await razorpayClassAPi
      .RazorpayApi({
        courseId: courseData.id,
        user,
        couponId: courseData.coupon_id || undefined,
        gst_no: gst_no,
        business_name: business_name,
        billing_address_id: billing_address_id,
      })
      .then(() => setPaymentEvent({ ...paymentEvent, intialized: true }))
      .catch(toastApiError);
    setModalState(false);
  };

  /*******Free Course Enrollment ******/
  const EnrolNow = async () => {
    const manualEnrol = await courseSvc
      .freeCourseEnrol(courseData.id, userId)
      .then(() => {
        setPaymentEvent({ ...paymentEvent, intialized: true });
        return true;
      })
      .catch((err) => console.log(err));
    setModalState(false);

    if (manualEnrol) {
      Toast.success("Your Are Successfully Enrolled into Course");
      history.push("/");
    } else Toast.error("Something went wrong!");
  };

  /*******Razorpay Subscription ******/
  const SubscribeNow = async () => {
    await razorpaySubscriptionClass
      .SubscriptionProcess({
        courseData,
        user,
      })
      .then(() => setPaymentEvent({ ...paymentEvent, intialized: true }))
      .catch(toastApiError);
    setModalState(false);
  };

  const startPayment = (gstdetails = []) => {
    if ((courseData.discounted_cost || courseData.cost) > 0) {
      if (courseData.is_subscription && paymentEvent.type === "subscription") {
        return SubscribeNow();
      } else if (paymentEvent.type === "full_payment") {
        return PayByRazorpay(...gstdetails);
      }
    } else {
      return EnrolNow();
    }
  };

  const openCheckoutModal = async (event) => {
    if (courseData.is_subscription && event.payingBySubscription)
      setPaymentEvent({ ...paymentEvent, type: "subscription" });
    else if (!event.payingBySubscription)
      setPaymentEvent({ ...paymentEvent, type: "full_payment" });
    setModalState(true);
  };

  useEffect(() => {
    // check if user has registered demo slots
    if (
      user_demo_batches &&
      courseData &&
      !courseData.hasOwnProperty("userHasRegisteredDemo")
    ) {
      const registeredDemoSlot = user_demo_batches.find(
        (udb) =>
          udb.batch.course.id == courseId &&
          new Date().getTime() < new Date(udb.endto_date).getTime()
      );
      if (registeredDemoSlot)
        setCourseData({
          ...courseData,
          userHasRegisteredDemo: true,
          registeredDemoSlot,
        });
    }
  }, [courseData, user_demo_batches]);

  if (courseData === null)
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Loader style={{ width: "200px" }} />
      </div>
    );
  else if (!courseData) {
    history.push("/not-found");
    return null;
  } else {
    return (
      <div className="-mt-24">
        <STRLCourseDetailPage
          isPurchased={!!isPurchased}
          courseData={{
            ...courseFormatter(courseData),

            // Demo props
            userHasRegisteredDemo: courseData?.userHasRegisteredDemo,
            onViewDemoDetails: () =>
              history.push(
                `?action=${ACTION_TYPES.show_demo_details}&slotId=${courseData?.registeredDemoSlot?.id}`
              ),
            isDemoAvailable: courseData?.isDemoAvailable,
            availableSlots: courseData?.availableSlots,
            onBookDemo: () =>
              dispatch({
                type: SET_COURSE_TO_DEMO,
                payload: { courseId: courseData.id },
              }),
          }}
          multiLangData={courseMultiLangData || {}}
          viewCourse={(e) => {
            if (courseData.is_moodle_course) {
              courseSvc.openCourse({ courseId: courseData.id });
            } else {
              history.push(`/course/view/${courseData.id}`);
            }
          }}
          payNow={openCheckoutModal}
          courseModuleTopic={courseModule}
        />
        <Modal
          isOpen={modalState}
          onClose={() => setModalState(false)}
          width={"30vw"}
        >
          <CheckoutModal
            courseData={courseData}
            paymentFunction={startPayment}
            setCourseData={setCourseData}
            paymentEvent={paymentEvent}
            closeFunction={() => setModalState(false)}
          />
        </Modal>
      </div>
    );
  }
}
